var downButton = (function($) {
  return {
    $elem: '.down_button',
    slide: 0,
    anchors: [],
    init: function() {

      window.addEventListener('scroll', function() {
        downButton.show();
        downButton.checkAnchor();
      });
      downButton.show();
      downButton.getAnchors();

      $(this.$elem).on('click', function(e) {
        e.preventDefault();
        var hb = $('html, body'),
          t = $('.pg--anchor').eq(downButton.slide);


        hb.animate({
          scrollTop: t.offset().top
        }, 800);
      });
    },
    getAnchors: function() {
      $('.pg--anchor').each(function(i) {
        downButton.anchors[i] = $(this);
      });

    },
    checkAnchor: function() {
      for(i=0;i<downButton.anchors.length;i++) {

        if (window.pageYOffset >= downButton.anchors[i].offset().top && window.pageYOffset <= (downButton.anchors[i].outerHeight() + downButton.anchors[i].offset().top)) {
          downButton.slide = i+1;
          if (downButton.slide > downButton.anchors.length-1) {
            downButton.slide = i;
          }
        }
        if (window.pageYOffset < downButton.anchors[0].offset().top) {
          downButton.slide = 0;
        }
      }

    },
    show: function() {
        var wT = $('.contactus').offset().top - window.outerHeight;
        var wPO = window.pageYOffset;
        if (wPO <= wT) {
          $(downButton.$elem).css('opacity', 1);
        } else {
          $(downButton.$elem).css('opacity', 0);
        }
    }
  };
})(jQuery);
