var BackButton = (function($) {
  return {
    container: $('.back_button'),
    active: true,
    init: function() {
      BackButton.container.click(function(e) {
        e.preventDefault();
        window.location = location.origin;

      });
      this.setEvents();
    },
    checkReferrer: function() {
      if (document.referrer.indexOf(location.protocol + "//" + location.host) !== 0 || document.referrer.length < 1) {
        return false;
      }

      return true;
    },
    setEvents: function(elem) {
      if ($(window).width() > 544 && this.checkReferrer()) {
        BackButton.container.css('display','block');
        $('.down_button').css('left', '50px');
      } else {
        $('.down_button').css('left', '0');
        BackButton.container.css('display','none');
      }
      $(window).resize(function() {
        if ($(window).width() > 544 && BackButton.checkReferrer()) {
          BackButton.container.css('display','block');
          $('.down_button').css('left', '50px');
        } else {
          BackButton.container.css('display','none');
          $('.down_button').css('left', '0');
        }
      });
    }
  };
})(jQuery);
