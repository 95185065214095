var About = (function($) {
  return {
    getIEVersion: function() {
        var agent = navigator.userAgent;
        var reg = /MSIE\s?(\d+)(?:\.(\d+))?/i;
        var matches = agent.match(reg);
        if (matches != null) {
            return { major: matches[1], minor: matches[2] };
        }
        return { major: "-1", minor: "-1" };
    },
    init: function() {
      People.init();
      if (!this.isMobile && (this.getIEVersion().major == -1 || this.getIEVersion().major > 10)) {
        FollowTop.init();
      }

    }
  };
})(jQuery);

Initialize.loadPage('about', About);
