var ContactUs = (function($) {
  return {
    controller: new ScrollMagic.Controller(),
    goto: '.foot_cta',
    scene: null,
    init: function() {
      this.scene = new ScrollMagic.Scene({
        triggerElement: ".slide.contactus",
        triggerHook: 1,
        offset: 0,
        duration: $('.slide.contactus').outerHeight()
      })
      .setTween(".contactus__expand", 1.5, {top: 0, left: 0, width: '100%', height: '100%', ease: Circ.easeInOut})
      //.addIndicators({name: "Conctact Us (duration: 0)"}) // add indicators (requires plugin)
      .addTo(ContactUs.controller);


      $(this.goto).on('click', function(e) {
         if ($(window).width() > 544) {
          e.preventDefault();
          ContactUs.controller.scrollTo('.contactus');
        }

      });

      this.controller.scrollTo(function(target) {
          TweenMax.to(window, 3, {
            scrollTo : {
              y : target, // scroll position of the target along y axis
              autoKill : true // allows user to kill scroll action smoothly
            },
            ease : Cubic.easeInOut
          });
        });
    }
  };
})(jQuery);
