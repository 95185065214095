var SlideCopy = (function($) {
  return {
    container: $('.slide__copy'),
    height: null,
    init: function(height) {
      this.height = height;
      if ($(window).width() > 768 && $(window).width() < 1004) {
        this.height = 500;
      }

      if ($(window).height() < 900 && $(window).width() > 972) {
        this.height = 400;
      }


      this.container.each(function() {
        if ($(this).hasClass('slide__copy--middle') && $(window).height() > 572 && $(window).width() > 1004) {
          $(this).css({ top: SlideCopy.getTop($(this))});
        }
      });
    },
    getTop: function(elem) {
      return (this.height - elem.outerHeight()) / 2;
    }
  };

})(jQuery);
