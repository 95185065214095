var Transitions = (function($) {
  return {
    controller: new ScrollMagic.Controller(),
    scenes: [],
    firstContent:null,
    offset: -300,
    init: function() {

      $('.pg').each(function(i) {
        var that = this;
        var paused = false;
        Transitions.scenes[i] = new ScrollMagic.Scene({
            triggerElement: $(that)[0],
            triggerHook: 0.5,
            offset: Transitions.offset,
            duration: Transitions.getDuration(that)
          })
          .on('enter', function() {
            $(that).addClass('active');
            $(that).removeClass('closing');
          })

          .on('leave', function() {
            $(that).removeClass('active');
            $(that).addClass('closing');

          })
          .on('progress', function(e) {
            e.target.duration(Transitions.getDuration(that));
          })
          //.addIndicators({name: "PG Tween (duration: 0)"}) // add indicators (requires plugin)
          .addTo(Transitions.controller);

          Transitions.firstContent = (Transitions.firstContent != null) ? Transitions.firstContent : ($(that).hasClass('pg--content')) ? Transitions.scenes[i] : null;
      });
      Transitions.toggleOffests();
      Transitions.setEvents();
    },
    toggleOffests: function() {

      if ($(window).width() < 768 || $(window).height() < 400) {
        //Transitions.offset = 0;
      }
      for(i=0;i<Transitions.scenes;i++) {
        Transitions.scenes[i].offset(Transitions.offset);
      }
      if (($(window).width() > 768 || $(window).height() > 400) && Transitions.firstContent != null) {
        Transitions.firstContent.offset(-400);
      }

    },
    getDuration: function(elem) {
      console.log($(elem).outerHeight(), (Math.abs(Transitions.offset) * 4));
      return $(elem).outerHeight() + (Math.abs(Transitions.offset) * 4);
    },
    setEvents: function() {
      $(window).resize(function() {
          Transitions.toggleOffests();
      });
    }
  };
})(jQuery);
