var Arrow = (function($) {
  return {
    container: $('.arrow'),
    init: function() {
      this.container.each(function() {
        if ($(this).hasClass('arrow--nextslide')) {
          Arrow.nextSlide(this);
        }
      });
    },
    nextSlide: function(elem) {
      $(elem).click(function() {
        Home.controller.scrollTo('.slide.about', 1);
      });
    }
  };
})(jQuery);
