var Nav = (function($) {
  return {
    clickDelayTimer: null,
    clickDelay:300,
    $header: $('header.banner'),
    $nav: $('header.banner nav'),
    $page: $('.wrap'),
    $footer: $('footer'),
    didScroll: false,
    lastScrollTop: 0,
    delta: 5,
    init: function() {
      $('.burger-menu__click-region').on('click', function () {
        if(Nav.clickDelayTimer === null) {

          var $burger = $(this);

          $burger.toggleClass('active');
          Nav.$nav.toggleClass('active');
          Nav.$page.toggleClass('active');
          Nav.$footer.toggleClass('active');

          if(!$burger.hasClass('active')) {
            $burger.addClass('closing');
          }

          Nav.clickDelayTimer = setTimeout(function () {
            $burger.removeClass('closing');
            clearTimeout(Nav.clickDelayTimer);
            Nav.clickDelayTimer = null;
          }, Nav.clickDelay);
        }
      });

      window.addEventListener('scroll', function(e) {
          Nav.didScroll = true;
      });

      setInterval(function() {
          if (Nav.didScroll) {
              Nav.hasScrolled();
              Nav.didScroll = false;
          }
      }, 250);

    },
    hasScrolled: function() {
        var st = $(window).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(Nav.lastScrollTop - st) <= Nav.delta) {
            return;
        }

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > Nav.lastScrollTop && st > Nav.$header.outerHeight()){
            // Scroll Down
            Nav.$header.removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                Nav.$header.removeClass('nav-up').addClass('nav-down');
            }
        }

        Nav.lastScrollTop = st;
    }
  };

})(jQuery);
