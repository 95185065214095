var c2a = (function($) {
  return {
    $elem: '.foot_cta',
    init: function() {
      window.addEventListener('scroll', function() {
        c2a.show();
      });
    },
    show: function() {
        var wT = $('.contactus').offset().top - window.outerHeight;
        var wPO = window.pageYOffset;
        if (wPO <= wT) {
          $(c2a.$elem).css('opacity', 1);
        } else {
          $(c2a.$elem).css('opacity', 0);
        }

    }
  };
})(jQuery);
