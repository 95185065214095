var Process = (function($) {
  return {
    animDelay: 700,
    svg: null,
    svg_file: '/wp-content/themes/made-pd/dist/images/process.svg',
    shape: '.process__shape',
    container: '.process__container',
    body: '.process__body',
    content: '.process__content',
    content_p: '.process__content--copy',
    pagination: '.process__pagination a',
    max:0,
    page:1,
    scroller: null,
    pages: [],
    degree:-30,
    windowWidth: $(window).width(),
    init: function() {
      this.svg = Snap(this.shape).attr({viewBox: '0 0 880 994' });
      Snap.load(this.svg_file, this.load ) ;
      this.setEvents();
    },
    load: function(data) {
      var self = this.Process;
      self.svg.append( data );
      self.pages = self.svg.selectAll("path");

      self.pages.forEach(function(el, i) {
        if (i > 0) {
          self.max++;

          $(el.node).hover(function() {
              var name = el.attr('class');
              var info = $('[data-svg="'+name+'"]')[0];
              var header = $(info).children('header').children('h1').html();
              var content = $(info).children(self.body).html();
              self.page = i;
              clearInterval( self.scroller );
              self.scroller = null;

              self.setContent(self.page);

              $(el.node).addClass('active');



          }, function() {
            self.scroll();

          });

        }
      });

      self.scroll();
    },
    scroll: function() {
      var self = this;
      this.scroller = setInterval(function() {
        self.page++;

        if( self.page > self.max ) {
          self.page = 1;
        }
        self.scrollTo( self.page );

      }, 6000);
    },
    scrollTo: function(page) {

      var self = this;
      var currentSlide = self.pages[page];
      var name = currentSlide.attr('class');
      var info = $('[data-svg="'+name+'"]')[0];
      var header = $(info).children('header').children('h1').html();
      var content = $(info).children(self.body).html();
      self.rotate();

      self.setContent(self.page);

      $(currentSlide.node).addClass('active');
    },
    rotate: function() {
      if ($(window).width() <= 768) {
        this.degree = this.degree + -60;
        $(this.container).css({'transform': 'rotate('+ this.degree +'deg)'});
      }
    },
    setContent: function(num) {
      var self = this;
      var name = this.pages[num].attr('class');
      var info = $('[data-svg="'+name+'"]')[0];
      var header = $(info).children('header').children('h1').html();
      var content = $(info).children(this.body).html();
      $(this.content).removeClass('active');
      this.pages.forEach(function(el) {
        $(el.node).removeClass('active');
      });

      setTimeout(function () {
          $(self.content).addClass('active');
          $(self.content_p).html(content);
          $(self.content).children('h3').html(header);
        }, this.animDelay);


    },
    reset: function() {
      $(window).off("resize", this.resizer);

      this.setContent(1);

      $(this.container).css('transform', '');
      this.svg.remove();
      this.svg = null;
      $(this.container).append('<svg class="process__shape"></svg>');
      clearInterval( this.scroller );
      this.scroller = null;
      this.page = 1;
      this.max = 0;
      this.pages.forEach(function(el, i) {
        $(el.node).unbind('mouseenter mouseleave');
      });
      this.degree = -30;
      this.pages = [];
    },
    resizer: function() {
      if ($(window).width() !== this.Process.windowWidth) {
        this.Process.reset();
        this.Process.windowWidth = $(window).width();
        this.Process.init();

      }
    },
    setEvents: function() {
      var self = this;

      $(window).resize(this.resizer);
      $(this.pagination).each(function() {
        $(this).on('click', function(e) {
          e.preventDefault();

          clearInterval( self.scroller );
          self.scroller = null;

          var pg = self.page;
          pg = ($(this).attr('href') === '#prev') ? pg-1 : pg+1;
          if( pg > self.max) {
            pg = 1;
          }
          if (pg < 1) {
            pg = self.max;
          }
          self.page = pg;
          self.scrollTo( pg );
        });
      });
    }
  };
})(jQuery);
