var FollowTop = (function($) {
  return {
    top: 0,
    titles: null,
    elem: null,
    totalTop: 0,
    ran: false,
    re: null,
    end: 0,
    windowWidth: $(window).width(),
    init: function() {
      if ($(window).width() >= 1004) {
        this.ran = true;
        this.elem = $('.pg--intro .pg__title span');
        this.top = this.elem.offset().top + this.elem.outerWidth();
        this.elem.css({ 'position': 'fixed', 'top': this.top, 'zIndex' : 20 });
        this.titles = $('.pg--content .pg__title');
        for(i=0;i<this.titles.length;i++) {
          $(this.titles[i]).removeClass('pg__anim');
          $(this.titles[i]).parent().parent().parent().parent().parent().parent().css('zIndex', ( 20 + (i+1)));
          $(this.titles[i]).css({ 'left': 'auto', 'marginLeft' : '-85px', 'zIndex' : 20 });
          $(this.titles[i]).find('span').css({ paddingRight: '30px'});
        }
        this.end = $(this.titles[this.titles.length - 1]).parent().offset().top;
      }
      setTimeout(function() {
        FollowTop.setEvents();
      }, 500);


    },
    setEvents: function() {
      $(window).resize(this.resizer);
      window.addEventListener('scroll', this.doHeights, false);
    },
    doHeights: function() {
      if ($(window).width() >= 1004) {
        FollowTop.totalTop = FollowTop.elem.offset().top;
        if (FollowTop.totalTop > FollowTop.end) {
          FollowTop.totalTop = FollowTop.end;
        }
        for(i=0;i<FollowTop.titles.length;i++) {
          var top = FollowTop.totalTop - ($(FollowTop.titles[i]).parent().offset().top - FollowTop.elem.outerWidth()) - FollowTop.elem.outerWidth() - 23;
          $(FollowTop.titles[i]).css({ 'position': 'absolute', 'top': top, 'left': 'auto', 'marginLeft' : '-85px', 'zIndex' : 20 });
          $(FollowTop.titles[i]).find('span').css({ paddingRight: '30px'});
        }
      }
    },
    resizer: function() {
      if ($(window).width() !== this.FollowTop.windowWidth) {
        clearTimeout(this.FollowTop.re);
        this.FollowTop.re = setTimeout(function() {
          this.FollowTop.reset();
          this.FollowTop.init();
          console.log('done!');
        }, 500);

      }
    },
    reset: function() {
      this.ran = false;

      if (typeof this.elem.attr('style') !== typeof undefined && this.elem.attr('style') !== false) {
        this.elem.removeAttr('style');
      }

      window.removeEventListener('scroll', this.doHeights , false);
      for(i=0;i<this.titles.length;i++) {
        $(this.titles[i]).addClass('pg__anim');
        if (typeof $(this.titles[i]).attr('style') !== typeof undefined && $(this.titles[i]).attr('style') !== false) {
          $(this.titles[i]).removeAttr('style');
          $(this.titles[i]).find('span').removeAttr('style');
        }
      }
    }
  };
})(jQuery);
