var WormHole = (function($) {
  return {
    ww: window.innerWidth,
    wh: window.innerHeight,
    renderer: null,
    camera: null,
    scene: new THREE.Scene(),
    container: new THREE.Object3D(),
    loader: new THREE.TextureLoader(),
    dots: null,
    plane: null,
    maxDistance: null,
    center: new THREE.Vector3(0, 0, 0),
    width: 150,
    height: 150,
    ease: {
      hole: 1, //default 1
      depth: 0.8 //default 0.8
    },
    vector: null,
    ratioA: null,
    geom: new THREE.Geometry(),
    matrix: new THREE.Matrix4(),
    planeGeom: null,
    planeMat: new THREE.MeshBasicMaterial( {color: 0x000000, side: THREE.DoubleSide} ),
    mat: null,
    paused: false,
    renderMe: true,
    init: function() {

      $(window).bind("scroll", function(){
        if (window.pageYOffset > window.outerHeight) {
          WormHole.renderMe = false;
        } else {
          WormHole.renderMe = true;
          if (WormHole.paused) {
            WormHole.paused = false;
            requestAnimationFrame(WormHole.render);
          }

        }
      });

      WormHole.renderer = new THREE.WebGLRenderer({
        canvas: document.querySelector("canvas.wormhole"),
        antialias: true
      });
      WormHole.renderer.setSize(WormHole.ww, WormHole.wh);
      WormHole.renderer.setClearColor(0x000000);

      WormHole.scene.fog = new THREE.Fog(0x000000, 100, 160);

      WormHole.camera = new THREE.PerspectiveCamera(45, WormHole.ww / WormHole.wh, 0.1, 1000);
      WormHole.camera.position.y = 95;
      WormHole.camera.position.z = 50;




      WormHole.scene.add(WormHole.container);

      TweenMax.to(WormHole.container.rotation, 48, {
        y:Math.PI*2,
        ease:Power0.easeNone
      });

      WormHole.loader.crossOrigin = 'Anonymous';
      /* Options */

      WormHole.maxDistance = new THREE.Vector3(WormHole.width*0.5, WormHole.height*0.5).distanceTo(WormHole.center);

      TweenMax.to(WormHole.ease, 6, {
        hole: 2, //default 2
        depth: 1.5, //default 1.5
        yoyo: true,
        ease: Power1.easeInOut,
        repeat: -1
      });


      WormHole.createDots();
      requestAnimationFrame(WormHole.render);

      window.addEventListener("resize", WormHole.onResize);


    },
    render: function(a){
      var fps = 35;
      setTimeout( function() {
        if (WormHole.renderMe) {
          requestAnimationFrame(WormHole.render);
        } else {
          WormHole.paused = true;
        }
      }, 1000 / fps );


      for(var i=0;i<WormHole.dots.geometry.vertices.length;i++){
        WormHole.vector = WormHole.dots.geometry.vertices[i];
        WormHole.ratioA = (WormHole.vector.ratio * WormHole.ease.depth) + WormHole.ease.hole;
        WormHole.ratioA*= WormHole.vector.ratio * WormHole.vector.ratio * WormHole.vector.ratio * WormHole.vector.ratio;
        WormHole.vector.y = WormHole.ratioA * -150;
        WormHole.vector.y = Math.max(WormHole.vector.y, -100);
        WormHole.vector.y += Math.sin(-(WormHole.vector.dist*0.4) + (a * 0.004));
      }
      for(var c=0;c<WormHole.plane.geometry.vertices.length;c++){
        WormHole.vector = WormHole.plane.geometry.vertices[c];
        WormHole.ratioA = (WormHole.vector.ratio * WormHole.ease.depth) + WormHole.ease.hole;
        WormHole.ratioA*= WormHole.vector.ratio * WormHole.vector.ratio * WormHole.vector.ratio * WormHole.vector.ratio;
        WormHole.vector.y = WormHole.ratioA * -150;
        WormHole.vector.y = Math.max(WormHole.vector.y, -100);
        WormHole.vector.y += Math.sin(-(WormHole.vector.dist*0.4) + (a * 0.004));
      }

      WormHole.dots.geometry.verticesNeedUpdate = true;
      WormHole.plane.geometry.verticesNeedUpdate = true;

      WormHole.camera.lookAt(new THREE.Vector3(0, -20, 0));

      WormHole.renderer.render(WormHole.scene, WormHole.camera);
    },
    createDots: function() {
      WormHole.planeGeom = new THREE.PlaneGeometry( WormHole.width * 2, WormHole.height *2, WormHole.width, WormHole.height );
      WormHole.matrix.makeRotationX(-Math.PI*0.5);
      WormHole.planeGeom.applyMatrix(WormHole.matrix);
      for(var i=0;i<WormHole.planeGeom.vertices.length;i++){
        WormHole.vector = WormHole.planeGeom.vertices[i];
        WormHole.vector.dist = WormHole.vector.distanceTo(WormHole.center);
        WormHole.vector.ratio = (WormHole.maxDistance - WormHole.vector.dist) / (WormHole.maxDistance * 0.9);
      }
      WormHole.planeMat = new THREE.MeshBasicMaterial( {color: 0x000000, side: THREE.DoubleSide} );
      WormHole.plane = new THREE.Mesh( WormHole.planeGeom, WormHole.planeMat );
      WormHole.container.add( WormHole.plane );

      for(var x=(-WormHole.width*0.5);x<WormHole.width*0.5;x++){
        for(var z=(-WormHole.height*0.5);z<WormHole.height*0.5;z++){
          WormHole.vector = new THREE.Vector3(x * 1.2, 0, z * 1.2);
          WormHole.vector.dist = WormHole.vector.distanceTo(WormHole.center);
          WormHole.vector.ratio = (WormHole.maxDistance - WormHole.vector.dist) / (WormHole.maxDistance * 0.9);
          WormHole.geom.vertices.push(WormHole.vector);
        }
      }
      WormHole.mat = new THREE.PointsMaterial({
        color:0xffffff,
        map: WormHole.loader.load(location.origin + '/wp-content/themes/made-pd/dist/images/tag.png'),
        transparent: true,
        alphaTest: 0.4
      });
      WormHole.dots = new THREE.Points(WormHole.geom, WormHole.mat);
      WormHole.container.add(WormHole.dots);
    },
    onResize: function() {
      WormHole.ww = window.innerWidth;
      WormHole.wh = window.innerHeight;
      WormHole.camera.aspect = WormHole.ww / WormHole.wh;
      WormHole.camera.updateProjectionMatrix();
      WormHole.renderer.setSize(WormHole.ww, WormHole.wh);
    }
  };
})(jQuery);
