var People = (function($) {
  return {
    clickDelayTimer: null,
    clickDelay:300,
    init: function() {
      $('.people').each(function() {

          var that = this;
          $(this).find('a').click(function(){
            if(People.clickDelayTimer === null) {
              $(that).toggleClass('active');

              if(!$(that).hasClass('active')) {
                $(that).addClass('closing');
              }

              People.clickDelayTimer = setTimeout(function () {
                $(that).removeClass('closing');
                clearTimeout(People.clickDelayTimer);
                People.clickDelayTimer = null;
              }, People.clickDelay);
            }
          });

      });
    }
  };
})(jQuery);
