var SlideImage = (function($) {
  return {
    container:null,
    height: null,
    init: function(elem) {
      this.container = elem;
      this.container.each(function() {
        $(this).find('img').width($(this).outerWidth());
      });

    }
  };

})(jQuery);
