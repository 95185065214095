var nextSlide = (function($) {
  return {
    $elem: '.next_button',
    slide: 'services',
    init: function() {
      window.addEventListener('scroll', function() {
        nextSlide.show();
      });
      nextSlide.show();

      $(this.$elem).on('click', function(e) {
        e.preventDefault();
        Home.controller.scrollTo('.slide.' + nextSlide.slide, 1.5);
      });
    },
    show: function() {
        var wT = $('.contactus').offset().top - window.outerHeight;
        var wPO = window.pageYOffset;
        if (wPO <= wT && wPO >= window.innerHeight - $('header.banner').outerHeight()) {
          $(nextSlide.$elem).css('opacity', 1);
        } else {
          $(nextSlide.$elem).css('opacity', 0);
        }

    }
  };
})(jQuery);
