var ConnectingDots = (function($) {
  return {
    canvas: document.querySelector('canvas.connecting-dots'),
    ctx: null,
    colorDot: '#7f7f7f',
    color: '#fff',
    mousePosition: null,
    dots: null,
    interval: null,
    init: function() {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      this.canvas.style.display = 'block';
      this.ctx = this.canvas.getContext('2d');
      this.ctx.fillStyle = this.colorDot;
      this.ctx.lineWidth = 0.1;
      this.ctx.strokeStyle = this.color;
      this.mousePosition = {
        x: 30 * this.canvas.width / 100,
        y: 30 * this.canvas.height / 100
      };
      this.dots = {
        nb: 600,
        distance: 45,
        d_radius: 80,
        array: []
      };

      window.onmousemove = function(parameter) {
        ConnectingDots.mousePosition.x = parameter.pageX;
        ConnectingDots.mousePosition.y = parameter.pageY - $('.connecting-dots').offset().top;
      };

      window.onresize = function() {
        ConnectingDots.canvas.width = window.innerWidth;
        ConnectingDots.canvas.height = window.innerHeight;
        window.clearInterval(ConnectingDots.interval);
        ConnectingDots.init();
      };

      this.mousePosition.x = window.innerWidth / 2;
      this.mousePosition.y = window.innerHeight / 2;

      this.interval = setInterval(ConnectingDots.createDots, 1000/30);
    },
    Dot: function() {
      this.x = Math.random() * ConnectingDots.canvas.width;
      this.y = Math.random() * ConnectingDots.canvas.height;

      this.vx = -0.5 + Math.random();
      this.vy = -0.5 + Math.random();

      this.radius = Math.random();
    },
    createDots: function(){
        if (ConnectingDots.dots.array.length > 50000) { ConnectingDots.dots.array = ConnectingDots.dots.array.slice(0, 40000); }
        ConnectingDots.ctx.clearRect(0, 0, ConnectingDots.canvas.width, ConnectingDots.canvas.height);
        for(i = 0; i < ConnectingDots.dots.nb; i++){
            ConnectingDots.dots.array.push(new ConnectingDots.Dot());
            dot = ConnectingDots.dots.array[i];
            dot.create();
        }

        dot.line();
        dot.animate();
    }

  };
})(jQuery);

ConnectingDots.Dot.prototype = {
    create: function(){

        ConnectingDots.ctx.beginPath();
        ConnectingDots.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ConnectingDots.ctx.fill();
    },

    animate: function(){
        for(i = 0; i < ConnectingDots.dots.nb; i++){

            var dot = ConnectingDots.dots.array[i];

            if(dot.y < 0 || dot.y > ConnectingDots.canvas.height){
                dot.vx = dot.vx;
                dot.vy = - dot.vy;
            }
            else if(dot.x < 0 || dot.x > ConnectingDots.canvas.width){
                dot.vx = - dot.vx;
                dot.vy = dot.vy;
            }
            dot.x += dot.vx;
            dot.y += dot.vy;
        }
    },

    line: function(){

        for(i = 0; i < ConnectingDots.dots.nb; i++){
            for(j = 0; j < ConnectingDots.dots.nb; j++){
                i_dot = ConnectingDots.dots.array[i];
                j_dot = ConnectingDots.dots.array[j];

                if((i_dot.x - j_dot.x) < ConnectingDots.dots.distance && (i_dot.y - j_dot.y) < ConnectingDots.dots.distance && (i_dot.x - j_dot.x) > - ConnectingDots.dots.distance && (i_dot.y - j_dot.y) > - ConnectingDots.dots.distance){
                    if((i_dot.x - ConnectingDots.mousePosition.x) < ConnectingDots.dots.d_radius && (i_dot.y - ConnectingDots.mousePosition.y) < ConnectingDots.dots.d_radius && (i_dot.x - ConnectingDots.mousePosition.x) > - ConnectingDots.dots.d_radius && (i_dot.y - ConnectingDots.mousePosition.y) > - ConnectingDots.dots.d_radius){
                        ConnectingDots.ctx.beginPath();
                        ConnectingDots.ctx.moveTo(i_dot.x, i_dot.y);
                        ConnectingDots.ctx.lineTo(j_dot.x, j_dot.y);
                        ConnectingDots.ctx.stroke();
                        ConnectingDots.ctx.closePath();

                    }
                }
            }
        }
    }
};


