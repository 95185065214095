var VertText = (function($) {
  return {
    container: $('.slide__title'),
    height: null,
    init: function() {
      this.height = this.container.height();
      this.container.each(function() {
        $('span', this).css({ width: VertText.height, top: VertText.height });
      });
    }
  };

})(jQuery);
